<template>
  <div class="admin-saleImpsales_import_idorts">
    <advanced-data-table
      v-if="render"
      :action="`${isDaily ? 'dailySaleRecords' : 'saleRecords'}/get`"
      :headers="headers"
      ref="table"
      :show-limit="!justImported"
      :filters.sync="filters"
      :search="false"
      limit="50"
    >
      <template #cell(user_id)="{item}">
        {{ item.book.user ? item.book.user.name : "None" }}
      </template>

      <template #cell(sales_import_id)="{item}">
        {{ item.sales_import ? item.sales_import.id : "None" }}
      </template>

      <template #cell(book_id)="{item}">
        <span v-tooltip.top-center="item.book.title">{{
          truncate(item.book.title, 40)
        }}</span>
      </template>

      <template #cell(sales_channel)="{item}">
        <span>
          {{
            item.sales_channel ? truncate(item.sales_channel.name, 20) : "None"
          }}
        </span>
      </template>

      <template #cell(territory_id)="{item}">
        <span v-if="item.territory" v-tooltip="item.territory.name">
          {{ item.territory ? item.territory.code : "None" }}
        </span>
      </template>

      <template #cell(currency_id)="{item}">
        {{ item.currency ? item.currency.code : "Unknown" }}
      </template>

      <template #cell(date_of_sale)="{item}">
        {{ formatDate(item.date_of_sale) }}
      </template>

      <template #cell(unit_price)="{item}">
        {{
          formatCurrency(
            item.unit_price,
            item.currency ? item.currency.code : "GBP"
          )
        }}
      </template>

      <!-- ISBN -->
      <template #cell(isbn-asin)="{item}">
        {{ getISBN(item.book) }}
      </template>
      <template #cell(royalty_amount)="{item}">
        {{ formatCurrency(item.royalty_amount, item.currency.code) }}
      </template>

      <template #cell(status)="{item}">
        <!-- Use statusVariant from @/mixins/colors.js -->
        <h5 class="m-0 single-row">
          <b-badge :variant="statusVariant(item.status)" class="mr-1">{{
            $t(item.status)
          }}</b-badge>
          <ebp-note-button :text="truncate(item.notes)" />
        </h5>
      </template>

      <!-- Actions -->
      <template #actions="{ item }">
        <ebp-button
          outlined
          variant="danger"
          size="sm"
          class="ml-1"
          :loading="removing === item.id"
          @click="remove(item)"
          v-tooltip.top-center="$t('delete')"
        >
          <i class="bx bxs-trash"></i>
        </ebp-button>
      </template>

      <!-- Filters -->
      <template #filters v-if="!justImported">
        <!-- Date range filter -->
        <adt-filter :label="$t('date-range')">
          <date-picker
            v-model="filters.date_range"
            :placeholder="$t('date-range')"
            range
            valueType="format"
          />
        </adt-filter>

        <!-- Sales channel filter -->
        <adt-filter :label="$t('sales-channel')">
          <item-search
            action="salesChannels/searchParents"
            label="name"
            trackBy="id"
            v-model="filters.sales_channel_id"
          />
        </adt-filter>

        <!-- User filter -->
        <adt-filter :label="$t('client')">
          <item-search
            action="users/search"
            label="name"
            trackBy="id"
            v-model="filters.user_id"
          />
        </adt-filter>

        <!-- Book filter -->
        <adt-filter :label="$t('book')">
          <item-search
            action="books/search"
            label="title"
            trackBy="id"
            v-model="filters.book_id"
          />
        </adt-filter>

        <!-- Currency filter -->
        <adt-filter :label="$t('currency')" v-if="!isDaily">
          <item-search
            action="currencies/search"
            label="code"
            trackBy="id"
            v-model="filters.currency_id"
          />
        </adt-filter>
      </template>
    </advanced-data-table>

    <import-summary
      :import_id="$route.query.sales_import_id"
      :failed="$route.query.failed"
      :processed="$route.query.processed"
      :totalRoyalty="$route.query.totalRoyalty"
      :currency="$route.query.currency"
      :totalParsed="$route.query.totalParsed"
      :totalSales="$route.query.totalSales"
      :totalSaleCount="$route.query.totalSaleCount"
      :totalCommision="$route.query.totalCommision"
      :isDaily="isDaily"
      v-if="justImported && render"
    />

    <!-- SalesChannel add modal -->
    <ebp-modal
      :open.sync="showAddSaleRecord"
      :tabs="addTabs"
      width="50%"
      v-if="render"
    >
      <template #error><error :err.sync="error"/></template>

      <!-- Edit details -->
      <template #import>
        <AddSaleImport @added="added" />
      </template>
    </ebp-modal>
  </div>
</template>

<script>
import AdvancedDataTable from "@/components/advanced-data-table";
import AdtFilter from "@/components/core/adt-filter";
import saleImportSchema from "@/validation-schemas/sale-import";
import salesRecordStatuses from "@/fixtures/sale-record-statuses";
import ItemSearch from "@/components/core/item-search";
import ImportSummary from "@/components/imports/import-summary";
import DatePicker from "@/components/core/DatePicker";

import AddSaleImport from "@/views/admin/sales/imports/Add";
import truncate from "@/helpers/truncate";
import EbpNoteButton from "../../../../components/ebp-note-button";
import bookHelper from "@/helpers/book";
export default {
  name: "SaleRecords",
  components: {
    EbpNoteButton,
    AdvancedDataTable,
    AddSaleImport,
    ItemSearch,
    AdtFilter,
    ImportSummary,
    DatePicker
  },
  data() {
    return {
      salesRecordStatuses: salesRecordStatuses.map(s => ({
        text: this.$t(s),
        value: s
      })),
      editedItem: null,
      saving: false,
      open: false,
      showAddSaleRecord: false,
      error: null,
      addTabs: ["import"],
      removing: false,
      filters: {
        status: null,
        sales_import_id: null,
        sales_channel_id: null,
        book_id: null,
        territory_id: null,
        currency_id: null,
        date_range: []
      },
      processing: false,
      render: true
    };
  },
  computed: {
    justImported() {
      return this.$route.query.step === "2";
    },
    isDaily() {
      return this.$route.meta.daily;
    },
    headers() {
      let headers = [
        {
          key: this.isDaily ? "daily_sale_import_id" : "sales_import_id",
          label: "sales-import",
          sortable: false
        },
        { key: "user_id", label: "client", sortable: false },
        { key: "book_id", label: "book", sortable: false },
        {
          key: "sales_channel",
          label: "sales-channel",
          sortable: false
        },
        { key: "territory_id", label: "territory", sortable: false },
        { key: "date_of_sale", label: "date-of-sale", sortable: true },
        { key: "net_sales", label: "sales" },
        "status",
        "isbn-asin"
      ];

      if (!this.isDaily) {
        headers = headers.concat([
          {
            key: "currency_id",
            label: "currency",
            sortable: false
          },
          { key: "royalty_amount", label: "Royalty" },
          { key: "unit_price", label: "unit-price" }
        ]);
      }

      return headers;
    }
  },
  methods: {
    truncate,
    getISBN: bookHelper.getISBN,
    added() {
      this.showAddSaleRecord = false;
      this.$refs.table.currentPage = 1;
      this.$refs.table.get();
    },
    async remove(record) {
      if (
        confirm(this.$t("remove-message", { name: this.$t("this-record") }))
      ) {
        this.removing = record.id;
        try {
          await this.$store.dispatch(
            `${this.isDaily ? "dailySaleRecords" : "saleRecords"}/delete`,
            record.id
          );
          this.$refs.table.change("id", record.id);
        } catch (err) {
          console.error(err);
        }
        this.removing = false;
      }
    },
    edit(saleImport) {
      this.editedItem = saleImport;
      this.open = true;
    },
    async save() {
      this.error = null;
      const saleImport = this.editedItem;
      const validation = saleImportSchema.validate(saleImport);

      if (validation.error) {
        this.error = validation.error.message;
      } else {
        this.saving = true;
        try {
          await this.$store.dispatch(
            `${this.isDaily ? "dailySaleImports" : "saleImports"}/update`,
            saleImport
          );
          this.$refs.table.change("id", saleImport.id, saleImport);
        } catch (err) {
          this.error = this.errorRes(err);
        }
        this.saving = false;
      }
    }
  },
  watch: {
    isDaily() {
      this.render = false;
      this.$nextTick(() => (this.render = true));
    }
  }
};
</script>

<style></style>
